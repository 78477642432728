import * as yup from "yup";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../Drawers/SearchAndFilterDrawer/utils";
import moment from "moment";

export type CreateBulkMessage = {
  name: string;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
  broadcast_type: string;
  throttling_rate: string;
  filterApplied: boolean;
  broadcast_date: string;
  broadcast_time: string;
  from: string;
  reduce_count: number;
};

export const defaultValues = {
  name: "",
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  broadcast_type: "scheduled",
  throttling_rate: "",
  filterApplied: false,
  broadcast_date: "",
  broadcast_time: "",
  from: "",
  reduce_count: 0,
};

export const createBulkMessageValidation = yup.object().shape({
  name: yup.string().required("Broadcast Name is required."),
  broadcast_type: yup.string().required("Schedule Type is required."),
  throttling_rate: yup.string().required("Throttling Type is required."),
  from: yup.string().required("Please select a line."),
  broadcast_date: yup
    .string()
    .test(
      "scheduleDate",
      "Schedule Date must be today or in future.",
      function (value) {
        const { broadcast_type } = this.parent;
        return broadcast_type !== "send_now"
          ? !!value && !moment(value).isBefore(moment(), "day")
          : true;
      }
    ),
  broadcast_time: yup
    .string()
    .test(
      "scheduleDate",
      "Schedule Time must be greater than current time.",
      function (value) {
        const { broadcast_type, broadcast_date, broadcast_time } = this.parent;
        return broadcast_type !== "send_now"
          ? !!value &&
              moment(broadcast_date + " " + broadcast_time).isAfter(moment())
          : true;
      }
    )
    .test(
      "scheduleDate",
      "Schedule Time must be between 10:00AM to 06:00PM",
      function (value) {
        const format = "HH:mm";
        const { broadcast_type, broadcast_time } = this.parent;
        return broadcast_type !== "send_now"
          ? !!value &&
              moment(broadcast_time, format).isBetween(
                moment("10:00", format),
                moment("18:00", format)
              )
          : true;
      }
    ),
});
