import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  addCampaignDocuments,
  addCampaignNotes,
  getCampaigns,
  removeCampaignDocuments,
} from "../../../utils/api/campaign.api";
import { Drawer, Dropdown, Input, PaginatedTable } from "../../common";
import ConfirmationDrawer from "../../common/ConfirmationDrawer";
import Loader from "../../common/Spinner";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import { SearchIcon } from "../../icons/svg";
import CreateCampaign from "../CreateCampaign/CreateCampaign";
import {
  DocumentApiRequest,
  DocumentRemoveApiRequest,
  NotesApiRequest,
} from "./utils";

const activeHeader = [
  { id: 1, value: "Name", label: "name" },
  { id: 5, value: "Documents", label: "documents" },
  { id: 6, value: "Notes", label: "notes" },
  { id: 7, value: "Feedback", label: "feedback" },
  { id: 8, value: "Actions", label: "actions" },
];

const CampaignListing = ({ status }: { status: string }) => {
  const [addNotes, setAddNotes] = useState<boolean>(false);
  const [campaignNotes, setCampaignNotes] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const [isCreateCampaignOpen, setIsCreateCampaignOpen] =
    useState<boolean>(false);
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const toast = useToast();

  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);

  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["campaignsApi", page, perPage, status, searchStr],
    queryFn: () => {
      const params: any = { status };
      if (!searchStr) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchStr;
      }

      return getCampaigns(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    setCount(data?.data?.total_pages * 10 ?? 0);
  }, [data]);

  const mutation = useMutation<any, Error, NotesApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await addCampaignNotes(payload);
        setAddNotes(false);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error Updateding: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["campaignsApi"]);
    },
  });

  const documentMutation = useMutation<any, Error, DocumentApiRequest, unknown>(
    {
      mutationFn: async (payload) => {
        try {
          const response = await addCampaignDocuments({
            ...payload,
            id: selectedCampaignId,
          });
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      },
      onSuccess: () => {
        toast({
          description: "Document Added Successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      },
      onError: (error: any) => {
        toast({
          description: `Error Uploading: ${
            error?.response?.data?.errors?.toString() ?? "Something went wrong"
          }`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["campaignsApi"]);
      },
    }
  );

  const documentRemoveMutation = useMutation<
    any,
    Error,
    DocumentRemoveApiRequest,
    unknown
  >({
    mutationFn: async (payload) => {
      try {
        const response = await removeCampaignDocuments(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Attachment removed successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      onClosePopupOpen();
    },
    onError: (error: any) => {
      toast({
        description: `Error Removing: ${
          error?.response?.data?.errors?.attachments?.toString() ??
          "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["campaignsApi"]);
    },
  });

  const options: SelectOptionProps[] = [
    { label: "Add Notes", value: "add-notes" },
    { label: "Upload Documents", value: "upload-documents" },
  ];

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }

      const formData = new FormData();
      formData.append("file", file);
      e.target.value = "";

      try {
        // You can write the URL of your server or any other endpoint used for file upload
        documentMutation.mutate({ id: selectedCampaignId, file: formData });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleRemoveDocument = async (attachmentId: number, id: string) => {
    await documentRemoveMutation.mutate({ id, attachmentId });
  };

  const handleSelect = (option: SelectOptionProps, id: string) => {
    setSelectedCampaignId(id);
    if (option.value === "add-notes") {
      setAddNotes(true);
    }

    if (option.value === "upload-documents") {
      document.getElementById("file-upload")?.click();
    }
  };

  const activeData = data?.data?.campaigns.map((item: any) => {
    return {
      name: (
        <HStack gap="1rem" justifyContent="space-between">
          <HStack alignItems="flex-start" gap="1rem">
            {item?.dealership_image ? (
              <Image
                src={item?.dealership_image}
                width={38}
                height={38}
                style={{
                  borderRadius: "50%",
                }}
                alt="avatar"
              />
            ) : (
              <AccountCircleIcon />
            )}
            <Text textStyle="h6" fontWeight="500">
              <TooltipIfOverflow>{item?.dealership_name}</TooltipIfOverflow>
            </Text>
          </HStack>
          {!["pending", "approved"].includes(status) && (
            <Box
              as="span"
              padding="0.62rem 0.75rem"
              cursor="pointer"
              color={"white"}
              fontSize="1rem"
              fontWeight="400"
              lineHeight="1.25rem"
              borderRadius="0.375rem"
              minWidth="2.0625rem"
              textAlign="center"
              background={"var(--secondary-500)"}
              whiteSpace="nowrap"
              onClick={() => {
                setIsCreateCampaignOpen(true);
                setSelectedCampaignId(item.id);
              }}
            >
              Register
            </Box>
          )}
        </HStack>
      ),
      documents: item?.attachments?.length ? (
        <VStack gap="0.5rem" alignItems="flex-start" w="100%">
          {item.attachments?.map(
            (attachment: { id: number; url: string; filename: string }) => {
              return (
                <HStack justifyContent="space-between" w="100%">
                  <Text color="var(--primary-500)">
                    <a href={attachment.url} download>
                      <TooltipIfOverflow>
                        {attachment.filename}
                      </TooltipIfOverflow>
                    </a>
                  </Text>
                  <Button
                    variant="outline"
                    padding="0.35rem"
                    onClick={() => {
                      setPopup({
                        name: "Remove Document",
                        description:
                          "Are you sure you want to remove this document?",
                        value: String(attachment.id),
                      });
                      setSelectedCampaignId(item?.id || "");
                      onOpenPopupOpen();
                    }}
                  >
                    <DeleteIcon
                      style={{ color: "var(--grey-800)", width: "1.2rem" }}
                    />
                  </Button>
                </HStack>
              );
            }
          )}
        </VStack>
      ) : (
        <>- -</>
      ),
      notes: (
        <HStack gap="1rem">
          <VStack alignItems="flex-start" gap="0">
            {item?.notes?.client_notes?.length ? (
              <>
                {item?.notes?.client_notes
                  ?.split("\n")
                  ?.filter((value: string) => !!value)
                  .map((note: string, index: number) => (
                    <Text key={index} textStyle="h6" fontWeight="500">
                      <TooltipIfOverflow char={30}>
                        {`• ${note}` ?? "-"}
                      </TooltipIfOverflow>
                    </Text>
                  ))}
              </>
            ) : (
              <>- -</>
            )}
          </VStack>
        </HStack>
      ),
      feedback: (
        <HStack gap="1rem">
          <VStack alignItems="flex-start" gap="0">
            {item?.notes?.feedback?.length && (
              <>
                {item?.notes?.feedback
                  ?.split("\n")
                  ?.filter((value: string) => !!value)
                  .map((note: string, index: number) => (
                    <Text key={index} textStyle="h6" fontWeight="500">
                      <TooltipIfOverflow char={30}>
                        {`• ${note}` ?? "-"}
                      </TooltipIfOverflow>
                    </Text>
                  ))}
              </>
            )}
          </VStack>
        </HStack>
      ),
      status: (
        <Box
          as="span"
          width="5.875rem"
          padding="0.25rem 0.625rem"
          borderRadius="2.5rem"
          border="1px solid var(--secondary-600)"
          color="var(--secondary-700)"
          fontSize="0.875rem"
        >
          Active
        </Box>
      ),
      actions: (
        <Dropdown
          options={options}
          onSelect={(option) => handleSelect(option, item?.id)}
        />
      ),
      created_at: moment(item.created_at).format("MM-DD-YYYY hh:mm A"),
      approved_at: moment(item.approved_at).format("MM-DD-YYYY hh:mm A"),
      rejected_at: moment(item.rejected_at).format("MM-DD-YYYY hh:mm A"),
    };
  });

  const onClose = () => {
    setIsCreateCampaignOpen(false);
  };

  const handleAddNotes = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: NotesApiRequest = {
      id: selectedCampaignId,
      notes: campaignNotes,
    };

    mutation.mutate(requestData);
  };

  const transformedHeaders = useMemo(() => {
    if (status === "pending") {
      return [...activeHeader, { label: "created_at", value: "Submitted At" }];
    }

    if (status === "rejected") {
      return [...activeHeader, { label: "rejected_at", value: "Rejected At" }];
    }

    if (status === "approved") {
      return [...activeHeader, { label: "approved_at", value: "Approved At" }];
    }

    return activeHeader;
  }, [status]);

  return (
    <>
      {(mutation.isLoading || documentMutation.isLoading) && (
        <Loader />
      )}
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Dealerships..."
              onChange={({ target: { value } }) => setSearchStr(value)}
              value={searchStr}
            />
          </HStack>
        </HStack>
        <VStack w="100%" alignItems="flex-start">
          <Box w="100%" alignItems="flex-start" background="white">
            <Text
              textStyle="caption"
              padding="1rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Dealerships ({data?.data?.total_count || 0})
            </Text>
            <PaginatedTable
              header={transformedHeaders}
              data={activeData}
              itemsPerPage={perPage}
              maxPageNumbers={5}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={data?.data?.total_count || 0}
              isLoadingData={isLoading}
            />
          </Box>
        </VStack>

        <Input
          id="file-upload"
          maxW="20rem"
          type="file"
          hidden
          onChange={handleUploadDocuments}
        />

        {/* Add Notes Drawer */}
        <Drawer
          isOpen={addNotes}
          onClose={() => setAddNotes(false)}
          title="Add Notes"
        >
          <VStack padding="1rem 1.5rem" alignItems="flex-start" w="100%">
            <VStack
              padding="0.5rem"
              alignItems="flex-start"
              w="100%"
              borderRadius="0.5rem"
              background="var(--grey-50)"
            >
              <Input
                label="Add Notes"
                placeholder="Type here..."
                isTextarea
                value={campaignNotes}
                onChange={({ target: { value } }) => setCampaignNotes(value)}
              />
            </VStack>
          </VStack>
          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            <Button variant="outline" w="100%" size="xl">
              Cancel
            </Button>
            <Button
              w="100%"
              size="xl"
              isDisabled={!campaignNotes}
              onClick={handleAddNotes}
            >
              Add Notes
            </Button>
          </DrawerFooter>
        </Drawer>
      </VStack>

      {isCreateCampaignOpen && (
        <CreateCampaign
          isOpen={isCreateCampaignOpen}
          onClose={onClose}
          isSimpSocial={false}
          refetchParent={() => null}
          selectedCampaignId={selectedCampaignId}
        />
      )}

      {isPopupOpen && (
        <ConfirmationDrawer
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          content={popup}
          apiCall={() => {
            handleRemoveDocument(Number(popup.value), selectedCampaignId);
          }}
          loading={documentRemoveMutation?.isLoading}
        />
      )}
    </>
  );
};

export default CampaignListing;
