import {
  Button,
  Checkbox,
  DrawerFooter,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { getCampaign, updateCampaign } from "../../../utils/api/campaign.api";
import {
  CreatableSelect,
  Drawer,
  Input,
  Select,
  TagsInput,
} from "../../common";
import {
  HELP_KEYWORDS,
  OPT_IN_KEYWORDS,
  OPT_OUT_KEYWORDS,
  US_APP_TO_PERSON,
} from "./constants";
import {
  CreateCampaignForm,
  createCampaignValidation,
  defaultValues,
} from "./util";
import Loader from "../../common/Spinner";

type data = {
  isSimpSocial?: boolean;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  isFromDealershipMenu?: boolean;
  selectedCampaignId: string;
};

interface ApiResult {
  data: any;
}

const CreateCampaign = ({ isOpen, onClose, selectedCampaignId }: data) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  const { handleSubmit, control, setValue, reset } =
    useForm<CreateCampaignForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(createCampaignValidation) as any,
    });

  const { isLoading } = useQuery({
    queryKey: ["campaignApi", selectedCampaignId],
    queryFn: () => getCampaign(selectedCampaignId),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: { data: CreateCampaignForm }) {
      reset(data.data);
      const messageSamples: any = data.data.message_samples.map((sample) => ({
        id: Date.now(),
        value: sample,
      }));
      const optOutKeywords: any = data.data.opt_out_keywords.map((sample) => ({
        label:
          OPT_OUT_KEYWORDS.find((keyword) => keyword.value === sample)?.label ??
          sample,
        value: sample,
      }));
      const optInKeywords: any = data.data.opt_in_keywords.map((sample) => ({
        label:
          OPT_IN_KEYWORDS.find((keyword) => keyword.value === sample)?.label ??
          sample,
        value: sample,
      }));
      const helpKeywords: any = data.data.help_keywords.map((sample) => ({
        label:
          HELP_KEYWORDS.find((keyword) => keyword.value === sample)?.label ??
          sample,
        value: sample,
      }));
      setValue("message_samples", messageSamples);
      setValue("opt_out_keywords", optOutKeywords);
      setValue("opt_in_keywords", optInKeywords);
      setValue("help_keywords", helpKeywords);
    },
  });

  const mutation = useMutation<ApiResult, Error, CreateCampaignForm, unknown>({
    mutationFn: async (payload) => {
      const transformedValues = {
        ...payload,
        message_samples: payload.message_samples.map((option) => option.value),
        opt_out_keywords: payload.opt_out_keywords.map(
          (option: any) => option.value
        ),
        opt_in_keywords: payload.opt_in_keywords.map(
          (option: any) => option.value
        ),
        help_keywords: payload.help_keywords.map((option: any) => option.value),
      };
      try {
        const response = await updateCampaign(transformedValues as any);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      onClose();
      toast({
        description: "Campaign registration process started successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error registering campaign: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["campaignsApi"]);
    },
  });

  const handleFormSubmit = (values: CreateCampaignForm) => {
    mutation.mutate({ ...values, id: selectedCampaignId });
  };

  return (
    <>
      {mutation.isLoading && <Loader />}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        title="Register Campaign"
        isLoading={isLoading}
      >
        <VStack
          alignItems="flex-start"
          padding="1rem 1.5rem"
          w="100%"
          gap="1rem"
        >
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Description"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                  isTextarea
                />
              );
            }}
            name="description"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Message Flow"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                  isTextarea
                  info={`
                  Campaign opt-in details must be provided, including all available methods and associated links or screenshots if not publicly accessible. Website opt-ins must link to a privacy policy with non-sharing statements and message rate disclosures. Example: "End users opt-in via www.acmesandwich.com, agreeing to texts and checking a box. Alternatively, they can text START to (111) 222-3333. T&Cs: www.acmesandwich.com/tc. Privacy Policy: www.acmesandwich.com/privacy."`}
                />
              );
            }}
            name="message_flow"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TagsInput
                  label="Message Samples"
                  value={value as any}
                  onChange={onChange}
                  error={error?.message}
                  flexDirection="column"
                  isRequired
                  minLength={20}
                  onAdd={(newValue: string) =>
                    setValue("message_samples", [
                      ...value,
                      { id: Date.now(), value: newValue },
                    ])
                  }
                  onRemove={(id: number) =>
                    setValue(
                      "message_samples",
                      value.filter((value) => value.id !== id)
                    )
                  }
                />
              );
            }}
            name="message_samples"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  isRequired
                  label="Us App To Person Usecase"
                  onChange={(value: any) =>
                    setValue("us_app_to_person_usecase", value.value)
                  }
                  placeholder="Select Usecase"
                  options={US_APP_TO_PERSON}
                  variant="default"
                  w="100%"
                  value={value}
                  error={error?.message || ""}
                />
              );
            }}
            name="us_app_to_person_usecase"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Checkbox
                  variant="whiteCheck"
                  size="lg"
                  onChange={({ target: { checked } }) =>
                    setValue("has_embedded_links", checked)
                  }
                  isChecked={value}
                >
                  Has Embedded Links
                </Checkbox>
              );
            }}
            name="has_embedded_links"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Checkbox
                  variant="whiteCheck"
                  size="lg"
                  onChange={({ target: { checked } }) =>
                    setValue("has_embedded_phone", checked)
                  }
                  isChecked={value}
                >
                  Has Embedded Phone
                </Checkbox>
              );
            }}
            name="has_embedded_phone"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CreatableSelect
                  label="Opt In Keywords"
                  onChange={(value: any) => {
                    setValue("opt_in_keywords", value);
                  }}
                  placeholder="Select Opt In Keywords"
                  options={OPT_IN_KEYWORDS}
                  variant="default"
                  isMulti
                  w="100%"
                  value={value}
                />
              );
            }}
            name="opt_in_keywords"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Opt In Message"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isTextarea
                />
              );
            }}
            name="opt_in_message"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CreatableSelect
                  label="Opt Out Keywords"
                  onChange={(value: any) => {
                    setValue("opt_out_keywords", value);
                  }}
                  placeholder="Select Opt Out Keywords"
                  options={OPT_OUT_KEYWORDS}
                  variant="default"
                  isMulti
                  w="100%"
                  value={value}
                />
              );
            }}
            name="opt_out_keywords"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Opt Out Message"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isTextarea
                />
              );
            }}
            name="opt_out_message"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <CreatableSelect
                  label="Help Keywords"
                  onChange={(value: any) => {
                    setValue("help_keywords", value);
                  }}
                  placeholder="Select Help Keywords"
                  options={HELP_KEYWORDS}
                  variant="default"
                  isMulti
                  w="100%"
                  value={value}
                />
              );
            }}
            name="help_keywords"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Help Message"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isTextarea
                />
              );
            }}
            name="help_message"
            control={control}
            rules={{
              required: true,
            }}
          />
        </VStack>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Register
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export default CreateCampaign;
