import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSearchParams } from "react-router-dom";
import { fetchData } from "../../utils/api/sms-templates.api";
import {
  getDealershipTemplates,
  getGlobalTemplates,
} from "../../utils/api/template.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import NoItem from "../LeadCenter/LeadDetails/DetailTabs/History/NoItem";
import { Input, Modal } from "../common";
import { SearchIcon } from "../icons/svg";
import { getFileNameFromURL } from "../../utils";

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  handleTemplate: (text: string, body?: string, attachmentUrls?: any) => void;
  type: string;
  parentMenu?: "global" | null;
};

function TemplateModal({
  isOpen,
  onClose,
  handleTemplate,
  type,
  parentMenu = null,
}: ImageModalProps) {
  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id } = dealership || {};

  const [searchParams] = useSearchParams();
  const dealershipId = id ? id : searchParams.get("dealership_id");
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(10);
  const [templates, setTemplates] = useState<any[]>([]);

  const getQueryFunc = () => {
    if (parentMenu) {
      return parentMenu === "global" && type === "email"
        ? getGlobalTemplates({
            page: page,
            per_page: perPage,
            searchStr: searchStr,
          })
        : fetchData(`/api/admin/sms_templates`, {
            page,
            per_page: perPage,
            search: searchStr,
          });
    } else {
      return type === "email"
        ? getDealershipTemplates({
            type: "",
            page,
            per_page: perPage,
            searchStr,
            dealership_id: dealershipId,
          })
        : fetchData(`/api/admin/dealerships/${dealershipId}/sms_templates`, {
            page,
            per_page: perPage,
            search: searchStr,
          });
    }
  };

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      `dealershipTemplateApi-${Date.now}`,
      dealershipId,
      page,
      perPage,
      searchStr,
    ],
    queryFn: () => getQueryFunc(),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: (data: any) => {
      setTemplates((prev: any) => [
        ...prev,
        ...((type === "email" ? data?.data?.templates : data?.sms_templates) ||
          []),
      ]);
    },
  });

  const totalItems =
    type === "email" ? data?.data?.total_count : data?.sms_templates_count;

  console.log("totalItems", totalItems, data);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Pick Template">
        <Box
          sx={{
            height: "calc(100vh - 350px)",
          }}
        >
          <Box padding="0 20px">
            <Input
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Templates..."
              onChange={({ target: { value } }) => {
                setSearchStr(value);
                setPage(1);
                setTemplates([]);
              }}
              width="100%"
              value={searchStr}
            />
          </Box>
          {!(isLoading || isFetching || !totalItems) ? (
            <>
              <div
                id="templatesModal"
                style={{
                  height: "calc(100vh - 415px)",
                  overflow: "auto",
                  padding: "20px",
                  gap: 5,
                }}
              >
                <InfiniteScroll
                  dataLength={totalItems}
                  next={() => setPage((prev) => prev + 1)}
                  hasMore={templates?.length < totalItems}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="templatesModal"
                >
                  {templates.length
                    ? templates?.map((item: any) => (
                        <HStack
                          key={item.id}
                          w="100%"
                          padding="1rem"
                          borderRadius="0.5rem"
                          border="1px solid var(--grey-300)"
                          alignItems="flex-start"
                          gap="1rem"
                          position="relative"
                          marginBottom="1rem"
                          cursor="pointer"
                          onClick={() => {
                            const attachmentUrls = item.attachment_urls?.map(
                              (attachment: string) => ({
                                filename: getFileNameFromURL(attachment),
                                url: attachment,
                              })
                            );
                            if (parentMenu === "global") {
                              // if an email then send subject,body otherwise name,body
                              handleTemplate(
                                type === "email" ? item.subject : item.name,
                                item.body,
                                type === "email"
                                  ? attachmentUrls
                                  : item.media_urls
                              );
                            } else {
                              handleTemplate(
                                type === "email" ? item.subject : item.body,
                                item.body,
                                type === "email"
                                  ? attachmentUrls
                                  : item.media_urls
                              );
                            }

                            onClose();
                          }}
                        >
                          <VStack alignItems="flex-start" gap="0.5rem" w="65%">
                            <VStack
                              alignItems="flex-start"
                              w="100%"
                              gap="0.25rem"
                              pb="0.5rem"
                            >
                              <Text textStyle="h4" fontWeight="500">
                                {type === "email" ? item.subject : item.name}
                              </Text>
                            </VStack>
                          </VStack>
                        </HStack>
                      ))
                    : !isLoading && !isFetching && <NoItem />}
                </InfiniteScroll>
              </div>
            </>
          ) : isLoading || isFetching ? (
            <div
              style={{
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span>Loading...</span>
            </div>
          ) : (
            <NoItem />
          )}
        </Box>
      </Modal>
    </>
  );
}

export default TemplateModal;
