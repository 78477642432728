const getCurrentEnvironment = () => {
  let env = "development";

  if (process.env.REACT_APP_ENV === "local") {
    env = "local";
  } else if (process.env.REACT_APP_ENV === "dev") {
    env = "dev";
  } else if (process.env.REACT_APP_ENV === "stg") {
    env = "stg";
  } else if (process.env.REACT_APP_ENV === "prod") {
    env = "prod";
  }
  return env;
};
type EnvironmentType = {
  readonly apiBaseUrl: string;
  readonly socketUrl: string;
};

type EnvironmentsType = {
  readonly development: EnvironmentType;
  readonly local: EnvironmentType;
  readonly stg: EnvironmentType;
  readonly dev: EnvironmentType;
  readonly prod: EnvironmentType;
};

export const Environments: EnvironmentsType = {
  local: {
    apiBaseUrl: `http://localhost:3000`,
    socketUrl: "wss://api-simpsocial-dev.tkxel-team/cable",
  },
  development: {
    apiBaseUrl: `https://api.simpsocial-dev.com`,
    socketUrl: "wss://api.simpsocial-dev.com/cable",
  },
  stg: {
    apiBaseUrl: `https://api.staging.simpsocial-dev.com`,
    socketUrl: "wss://api.staging.simpsocial-dev.com/cable",
  },
  dev: {
    apiBaseUrl: `https://api.simpsocial-dev.com`,
    socketUrl: "wss://api.simpsocial-dev.com/cable",
  },
  prod: {
    apiBaseUrl: `https://api.gocrm.ai`,
    socketUrl: "wss://api.gocrm.ai/cable",
  },
} as const;

export const SERVICES = {
  apiBaseUrl: (Environments as any)[getCurrentEnvironment()].apiBaseUrl,
  socketUrl: (Environments as any)[getCurrentEnvironment()].socketUrl,
};
