import * as yup from "yup";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../Drawers/SearchAndFilterDrawer/utils";
import moment from "moment";
import { isTimeBetween } from "../../../utils";

export type CreateBulkMessage = {
  name: string;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
  broadcast_type: string;
  throttling_rate: string;
  filterApplied: boolean;
  content: string;
  broadcast_date: string;
  broadcast_time: string;
  from: string;
  reduce_count: number;
  working_hour_type: string;
  working_hour: {
    week_start_time: string;
    week_end_time: string;
    weekend_timings: boolean;
    weekend_start_time: string;
    weekend_end_time: string;
  };
};

export const defaultValues = {
  name: "",
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  broadcast_type: "send_now",
  throttling_rate: "",
  filterApplied: false,
  content: "",
  broadcast_date: "",
  broadcast_time: "",
  from: "",
  reduce_count: 0,
  working_hour_type: "dealership",
  working_hour: {
    week_start_time: "",
    week_end_time: "",
    weekend_timings: false,
    weekend_start_time: "",
    weekend_end_time: "",
  },
};

export const createBulkMessageValidation = (settingHours: any) => {
  return yup.object().shape({
    name: yup.string().required("Broadcast Name is required."),
    broadcast_type: yup.string().required("Schedule Type is required."),
    throttling_rate: yup.string().required("Throttling Type is required."),
    content: yup.string().required("Message Body is required.").max(1599),
    from: yup.string().required("Please select a line."),
    broadcast_date: yup
      .string()
      .test(
        "scheduleDate",
        "Schedule Date must be today or in future.",
        function (value) {
          const { broadcast_type } = this.parent;
          return broadcast_type !== "send_now"
            ? !!value && !moment(value).isBefore(moment(), "day")
            : true;
        }
      ),
    broadcast_time: yup
      .string()
      .test(
        "scheduleDate",
        "Schedule Time must be greater than current time and in between campaign hours or dealership hours.",
        function (value) {
          const {
            broadcast_type,
            broadcast_date,
            broadcast_time,
            working_hour_type,
            working_hour,
          } = this.parent as any;
          const {
            week_start_time,
            week_end_time,
            weekend_timings,
            weekend_start_time,
            weekend_end_time,
          } = working_hour || {};
          const weekDay = moment().day();
          const isWeekend = weekDay === 6 || weekDay === 0;
          const timeBetween =
            working_hour_type === "custom"
              ? weekend_timings
                ? isWeekend
                  ? isTimeBetween(
                      weekend_start_time,
                      weekend_end_time,
                      broadcast_time
                    )
                  : isTimeBetween(
                      week_start_time,
                      week_end_time,
                      broadcast_time
                    )
                : isTimeBetween(week_start_time, week_end_time, broadcast_time)
              : isWeekend
              ? settingHours?.weekend_start_time
                ? isTimeBetween(
                    settingHours?.weekend_start_time,
                    settingHours?.weekend_end_time,
                    broadcast_time
                  )
                : true
              : isTimeBetween(
                  settingHours?.week_start_time,
                  settingHours?.week_end_time,
                  broadcast_time
                );

          return broadcast_type !== "send_now"
            ? !!value &&
                moment(broadcast_date + " " + broadcast_time).isAfter(
                  moment()
                ) &&
                timeBetween
            : true;
        }
      ),
    working_hour_type: yup.string(), // The new key to check working_hour
    // Conditionally apply the validation for `working_hour` based on `working_hour_type`
    working_hour: yup.object().when("working_hour_type", {
      is: (working_hour_type: string) => working_hour_type === "custom",
      then: yup.object().shape({
        week_start_time: yup
          .string()
          .required("Weekday Start Time is required.")
          .test(
            "is-greater-start-time",
            `Week Start Time must be greater than ${moment(
              settingHours?.week_start_time,
              "HH:mm"
            ).format("hh:mm A")}`,
            function (value) {
              return settingHours?.week_start_time
                ? moment(value, "HH:mm A").isSameOrAfter(
                    moment(settingHours?.week_start_time, "HH:mm A")
                  )
                : true;
            }
          ),
        week_end_time: yup
          .string()
          .required("Weekday End Time is required.")
          .test(
            "is-greater-weekday",
            `Weekday End Time must be greater than the Start Time ${
              settingHours?.week_end_time
                ? `and less than ${moment(
                    settingHours?.week_end_time,
                    "HH:mm"
                  ).format("hh:mm A")}`
                : ""
            }`,
            function (value) {
              const { week_start_time } = this.parent;
              const isBeforeEndTime = settingHours?.week_end_time
                ? moment(value, "HH:mm A").isSameOrBefore(
                    moment(settingHours?.week_end_time, "HH:mm A")
                  )
                : true;
              return (
                moment(value, "HH:mm A").isSameOrAfter(
                  moment(week_start_time, "HH:mm A")
                ) && isBeforeEndTime
              );
            }
          ),

        weekend_timings: yup.boolean(),

        weekend_start_time: yup.string().when("weekend_timings", {
          is: true,
          then: yup
            .string()
            .required("Weekend Start Time is required.")
            .test(
              "is-greater-weekend",
              `Weekend Start Time must be greater than ${moment(
                settingHours?.weekend_start_time,
                "HH:mm"
              ).format("hh:mm A")}`,
              function (value) {
                return settingHours?.weekend_start_time
                  ? moment(value, "HH:mm A").isSameOrAfter(
                      moment(settingHours?.weekend_start_time, "HH:mm A")
                    )
                  : true;
              }
            ),
          otherwise: yup.string(),
        }),

        weekend_end_time: yup.string().when("weekend_timings", {
          is: true,
          then: yup
            .string()
            .required("Weekend End Time is required.")
            .test(
              "is-greater-weekend",
              `Weekend End Time must be greater than the Start Time ${
                settingHours?.weekend_end_time
                  ? `and less than ${moment(
                      settingHours?.weekend_end_time,
                      "HH:mm"
                    ).format("hh:mm A")}`
                  : ""
              }`,
              function (value) {
                const { weekend_start_time } = this.parent;
                const isBeforeEndTime = settingHours?.weekend_end_time
                  ? moment(value, "HH:mm A").isSameOrBefore(
                      moment(settingHours?.weekend_end_time, "HH:mm A")
                    )
                  : true;
                return (
                  moment(value, "HH:mm A").isSameOrAfter(
                    moment(weekend_start_time, "HH:mm A")
                  ) && isBeforeEndTime
                );
              }
            ),
          otherwise: yup.string(),
        }),
      }),
      otherwise: yup.object().shape({}), // No validation if `working_hour_type` is not 'custom'
    }),
  });
};
