import * as yup from "yup";
import { LINK_REGEX } from "../../../../config";
import moment from "moment";

export type GeneralForm = {
  name: string;
  time_zone: string;
  website: string;
  credit_app_link: string;
  address: string;
  business_hours: {
    week_start_time: string;
    week_end_time: string;
    weekend_timings: boolean;
    weekend_start_time: string;
    weekend_end_time: string;
  };
  mms: boolean;
  initial_sms_opt_in: boolean;
  initial_sms_opt_message: string;
  thirty_days_opt_in: boolean;
  thirty_days_opt_message: string;
  tcpa_disposition: string;
};

export const defaultValues = {
  name: "",
  time_zone: "",
  website: "",
  address: "",
  credit_app_link: "",
  business_hours: {
    week_start_time: "",
    week_end_time: "",
    weekend_timings: false,
    weekend_start_time: "",
    weekend_end_time: "",
  },
  mms: false,
  initial_sms_opt_in: false,
  initial_sms_opt_message: '',
  thirty_days_opt_in: false,
  thirty_days_opt_message: '',
  tcpa_disposition: '',
};

export const generalFormValidation: any = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .max(50, "Name should have less than 50 characters.")
    .required("Name is required."),
  time_zone: yup.string().nullable().required("Timezone is required."),
  website: yup.string().nullable().matches(LINK_REGEX, "Invalid Website Link!"),
  // credit_app_link: yup.string().nullable().matches(LINK_REGEX, "Invalid  Link!"),
  address: yup.string().nullable().max(50),
  business_hours: yup.object().shape({
    week_start_time: yup
      .string()
      .nullable()
      .required("Start Time is required."),
    week_end_time: yup
      .string()
      .nullable()
      .required("End Time is required.")
      .test("is-greater", "end time should be greater", function (value) {
        const { week_start_time } = this.parent;
        return moment(value, "HH:mm A").isSameOrAfter(
          moment(week_start_time, "HH:mm A")
        );
      }),
    weekend_timings: yup.boolean(),
    weekend_start_time: yup.string().when("weekend_timings", {
      is: (weekend_timings: boolean) => weekend_timings,
      then: yup.string().nullable().required("Weekend Start Time is required."),
      otherwise: yup.string().nullable(),
    }),
    weekend_end_time: yup.string().when("weekend_timings", {
      is: (weekend_timings: boolean) => weekend_timings,
      then: yup
        .string()
        .nullable()
        .required("Weekend End Time is required.")
        .test("is-greater", "End Time should be greater", function (value) {
          const { weekend_start_time, weekend_timings } = this.parent;
          if (!weekend_timings) return true;
          return moment(value, "HH:mm A").isSameOrAfter(
            moment(weekend_start_time, "HH:mm A")
          );
        }),
      otherwise: yup
        .string()
        .nullable()
        .test("is-greater", "End Time should be greater", function (value) {
          const { weekend_start_time, weekend_timings } = this.parent;
          if (!weekend_timings) return true;
          return moment(value, "HH:mm A").isSameOrAfter(
            moment(weekend_start_time, "HH:mm A")
          );
        }),
    }),
  }),
});
