import {
  Box,
  Button,
  HStack,
  Image,
  Center,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useReducer, useRef, useState } from "react";
import TikTokImg from "../../assets/images/tiktok.svg";
import avatar from "../../assets/avatar.svg";
import comment from "../../assets/comment.svg";
import playVideo from "../../assets/playVideo.svg";
// import TikTokImg from "../../assets/TikTokImg.mp4";
import ReactPlayer from "react-player";
import muisicIcon from "../../assets/music.svg";
import heartIcon from "../../assets/heart.svg";
import unLikeHeart from "../../assets/unLikeHeart.svg";
import downArrow from "../../assets/downArraow.svg";
import { Input } from "../common";
import { CloseIcon, TiktokLogo } from "../icons/svg";
import { Close, KeyboardArrowDown } from "@mui/icons-material";
import {
  loginTiktok,
  getadds,
  getTiktokVideo,
  getTiktokAddGroupComments,
} from "../../utils/api/social-talk.api";
import { useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import Loader from "../common/Spinner";
import ChatIcon from "@mui/icons-material/Chat";
import DatePicker from "../common/DateRangePicker";
import moment from "moment";
import { Select } from "../common";
import Pagination from "@mui/material/Pagination";
import SimplePagination from "./SimplePagination";

const items = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);

const TikTok = () => {
  const dealership_id = useUserInfo("dealership")?.id;
  const dealership_name = useUserInfo("dealership")?.name;
  const [videoData, setVideoData] = useState<any>([]);
  const [comments, setComments] = useState<any>([]);
  const [openVideo, setOpenVideo] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [videosFetched, setVideosFetched] = useState<boolean>(false);
  const [status, setStatus] = useState("AD_STATUS_NOT_DELETE");
  const [dateRange, setDateRange] = useState<any>({
    start_date: moment().subtract(90, "days").toDate(),
    end_date: moment().toDate(),
  });
  const { data, isLoading, isError } = useQuery({
    queryKey: ["tiktoklogin", dealership_id],
    queryFn: loginTiktok,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { data: adds } = useQuery({
    queryKey: [
      "getadds",
      dealership_id,
      moment(dateRange?.start_date).format("YYYY-MM-DD") + " 00:00:00",
      moment(dateRange?.end_date).format("YYYY-MM-DD") + " 00:00:00",
      status,
      currentPage
    ],
    queryFn: getadds,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const fetchVideoData = async (video_id: any) => {
    const response = await getTiktokVideo(dealership_id, video_id);
    if (!response.success) {
      throw new Error("Network response was not ok");
    }
    return response;
  };
  const fetchVideoComments = async (group_id: any) => {
    const response = await getTiktokAddGroupComments(dealership_id, group_id);
    if (!response.success) {
      throw new Error("Network response was not ok");
    }
    return response;
  };
  useEffect(() => {
    if (adds && adds.data?.data?.ads?.data?.list?.length > 0) {
      // Filter out invalid video IDs and fetch data for valid ones
      const validAdds = adds?.data?.data?.ads?.data?.list?.filter(
        (add: any) => add?.video_id
      );
      setLoading(true);
      Promise.all(
        validAdds.map((add: any) =>
          fetchVideoData(add.video_id).catch((err) => ({
            error: err.message,
            video_id: add.video_id,
          }))
        )
      )
        .then((results) => {
          // Separate successful results from errors
          const successfulResults = results.filter((result) => result.success);
          const errors = results.filter((result) => !result.success);

          setVideoData(successfulResults);

          if (errors.length > 0) {
            console.error("Some video data requests failed:", errors);
          }
        })
        .catch((err) => {
          console.error("Error in Promise.all:", err);
        })
        .finally(() => {
          setLoading(false);
          setVideosFetched(true); // Mark videos as fetched
        });
    }
  }, [adds]);
  const [playingIndex, setPlayingIndex] = useState<any>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleVideoClick = (index: number) => {
    setPlayingIndex((prevIndex: any) => (prevIndex === index ? null : index));
    setIsModalOpen(true);
  };

  const handleVideoEnd = () => {
    setPlayingIndex((prevIndex: any) =>
      prevIndex === null ? 0 : (prevIndex + 1) % videoData?.length
    );
  };

  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleModalClose = () => {
    setPlayingIndex(null);
    setIsModalOpen(false);
  };

  const handleScroll = () => {
    if (isModalOpen) {
      handleModalClose();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  console.log(dateRange);
  const activityOptions = [
    {
      value: "AD_STATUS_NOT_DELETE",
      label: "All",
    },
    {
      value: "AD_STATUS_DONE",
      label: "Completed",
    },
    {
      value: "AD_STATUS_DELIVERY_OK",
      label: "In Progress",
    },
  ];

  const totalPages = adds?.data?.data?.ads?.data?.page_info?.total_page || 1;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setVideoData([])
  };



  return (
    <>
      {!isError ? (
        <>
          <HStack alignItems="center" p="1.5rem" justifyContent="space-between">
            <VStack position="relative" zIndex="999">
              <Select
                label=""
                onChange={(value: any) => setStatus(value?.value)}
                placeholder="Status"
                options={activityOptions}
                variant="default"
              />
            </VStack>

            <VStack position="relative" zIndex="999">
              <DatePicker
                label="Select Date Range"
                value={{
                  startDate: dateRange.start_date as unknown as Date,
                  endDate: dateRange.end_date as Date,
                }}
                onChange={(range: {
                  startDate: Date;
                  endDate: Date;
                  clear?: boolean;
                }) => {
                  setDateRange({
                    start_date: range?.startDate as any,
                    end_date: range?.endDate,
                  });
                }}
                // w="100%"
                shouldShowClear
                isRange
              />
            </VStack>
          </HStack>

          {!openVideo ? (
            <>
              <SimplePagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                maxPageNumbers={5} // Adjust as needed
              />
              <HStack gap="1.5rem" flexWrap="wrap" padding="1.5rem">
                {videoData?.map((data: any, index: any) => (
                  <VStack
                    // maxWidth="19.75rem"
                    // width="22.5%"
                    w="calc(24% - 0.5rem)"
                    key={data.id}
                  >
                    <Box
                      position="relative"
                      onClick={() => handleVideoClick(index)}
                    >
                      <Image
                        src={data?.data?.video?.data?.list[0]?.video_cover_url}
                        alt="tiktok"
                        height="552px"
                        objectFit="cover"
                      />
                      <HStack position="absolute" bottom="1.12rem" left="1rem">
                        <Image src={playVideo} alt="play-video" />
                        <Text color="white" fontSize="1rem" fontWeight="400">
                          {data.noOfViews}
                        </Text>
                      </HStack>
                      {playingIndex === index && (
                        <Box
                          position="absolute"
                          w="100%"
                          inset="0"
                          background="white"
                          zIndex="99"
                        >
                          <HStack width="100%">
                            <ReactPlayer
                              url={
                                data?.data?.video?.data?.list[0]?.preview_url
                              }
                              playing={true}
                              controls
                              width="100%"
                              height="552px"
                              onEnded={handleVideoEnd}
                            />
                            {/* <Box>
                            <HStack>
                              <Image src={avatar} alt="avatar" />
                              <Text>{dealership_name}</Text>
                            </HStack>
                          </Box> */}
                          </HStack>
                        </Box>
                      )}
                    </Box>

                    <Text>
                      {data?.description}
                      <Box as="span" color="#1565C0">
                        {data?.hashTag}
                      </Box>
                    </Text>
                    <HStack
                      justifyContent="space-between"
                      alignItems="center"
                      w="100%"
                    >
                      <HStack>
                        <Image src={avatar} alt="avatar" />
                        <Text>{dealership_name}</Text>
                      </HStack>

                      <HStack
                        // onClick={() => {
                        //   setOpenVideo(true);
                        //   setPlayingIndex(index);
                        // }}
                        opacity={0.5}
                        pointerEvents={"none"} // Enable/disable pointer events based on props.allowed
                      >
                        <Image src={comment} />
                        <Text color="#757575" fontSize="1rem" fontWeight="400">
                          {0}
                        </Text>
                      </HStack>
                    </HStack>
                  </VStack>
                ))}
              </HStack>
            </>
          ) : (
            <HStack gap="2rem">
              <Box
                backgroundImage={`${TikTokImg}`}
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                h="calc(100vh - 184px)"
                w="48rem"
                background="linear"
              >
                <Box
                  background="linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%)"
                  w="100%"
                  h="100%"
                  position="relative"
                >
                  <ReactPlayer
                    url={
                      videoData[playingIndex]?.data?.video?.data?.list[0]
                        ?.preview_url
                    }
                    playing={true}
                    controls
                    width="100%"
                    height="100%"
                    onEnded={handleVideoEnd}
                  />
                  <Button
                    background="#3D3D3B"
                    _hover={{ bg: "#3D3D3B" }}
                    outline="none"
                    border="none"
                    minW="2.5rem"
                    minH="2.5rem"
                    borderRadius="6.25rem"
                    padding="0"
                    position="absolute"
                    top="2.5rem"
                    left="1.5rem"
                    onClick={() => {
                      handleVideoEnd();
                      setOpenVideo(false);
                    }}
                  >
                    <HStack>
                      <Close />
                    </HStack>
                  </Button>
                  <Button
                    background="#3D3D3B"
                    _hover={{ bg: "#3D3D3B" }}
                    outline="none"
                    border="none"
                    minW="2.5rem"
                    minH="2.5rem"
                    borderRadius="6.25rem"
                    padding="0"
                    position="absolute"
                    top="50%"
                    right="1.5rem"
                  >
                    <HStack>
                      <KeyboardArrowDown />
                    </HStack>
                  </Button>
                </Box>
              </Box>
              <VStack
                alignItems="flex-start"
                h="calc(100vh - 182px)"
                overflow="auto"
              >
                <VStack
                  background="#F8F8F8"
                  padding="1rem"
                  borderRadius="1rem"
                  alignItems="flex-start"
                  w="29rem"
                >
                  <HStack>
                    <Image
                      src={avatar}
                      alt="avatar"
                      width="2.5rem"
                      height="2.5rem"
                    />
                    <VStack alignItems="flex-start" gap="0">
                      <Text fontSize="1.125rem" fontWeight="500">
                        John Doe
                      </Text>
                      <Text fontSize="0.875rem">Auto Expo (Ford)</Text>
                    </VStack>
                  </HStack>
                  <Text maxW="20rem" fontSize="1rem">
                    Accelerating into good times, braking for great memories!
                    🏎️📸
                    <Box as="span" color="#1565C0">
                      #CarAdventures.....
                    </Box>
                  </Text>

                  <HStack>
                    <Image src={muisicIcon} alt="music" />
                    <Text fontSize="0.875rem">
                      original sound - Edward Ronald
                    </Text>
                  </HStack>
                </VStack>

                <HStack gap="1.62rem" mt="1rem">
                  <HStack>
                    <HStack
                      background="#F1F1F2"
                      w="2rem"
                      h="2rem"
                      justifyContent="center"
                      borderRadius="6.25rem"
                    >
                      <Image src={heartIcon} />
                    </HStack>
                    <Text color="#5D5D5D" fontSize="0.75rem" fontWeight="600">
                      1.2 M
                    </Text>
                  </HStack>

                  <HStack>
                    <HStack
                      background="#F1F1F2"
                      w="2rem"
                      h="2rem"
                      justifyContent="center"
                      borderRadius="6.25rem"
                    >
                      <Image src={comment} />
                    </HStack>
                    <Text color="#5D5D5D" fontSize="0.75rem" fontWeight="600">
                      1.2 M
                    </Text>
                  </HStack>
                </HStack>

                <HStack
                  background="#F1F1F2"
                  padding="0.75rem"
                  borderRadius="0.5rem"
                  w="29rem"
                  justifyContent="space-between"
                  mt="0.75rem"
                >
                  <Text>https://www.tiktok.com/@John Doe/video/.....</Text>
                  <Text color="#2196F3" fontWeight="600" cursor="pointer">
                    Copy Link
                  </Text>
                </HStack>

                <VStack mt="1.19rem" alignItems="flex-start">
                  <Text color="#000" fontWeight="700" fontSize="1rem">
                    Comments (2992)
                  </Text>

                  <VStack mt="1.15rem">
                    <HStack
                      justifyContent="space-between"
                      w="29rem"
                      alignItems="flex-start"
                    >
                      <HStack alignItems="flex-start">
                        <Image
                          src={avatar}
                          alt="avatar"
                          width="2.5rem"
                          height="2.5rem"
                        />
                        <VStack alignItems="flex-start" gap="0">
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            color="#000"
                          >
                            Alberto
                          </Text>
                          <Text fontSize="1rem">Bro, the second one </Text>
                          <HStack gap="2.25rem">
                            <Text fontSize="0.875rem">1w ago</Text>
                            <Text fontSize="0.875rem">Reply</Text>
                          </HStack>

                          <Button
                            variant="ghost"
                            padding="0"
                            transition="0"
                            fontSize="0.875rem"
                            _hover={{ bg: "none" }}
                          >
                            <HStack>
                              <Text>View 11 replies</Text>
                              <Image src={downArrow} alt="arrow" />
                            </HStack>
                          </Button>
                        </VStack>
                      </HStack>

                      <VStack justifyContent="center">
                        <Image
                          src={unLikeHeart}
                          alt="alt"
                          w="1.02106rem"
                          h="0.875rem"
                        />
                        <Text color="#616161" fontSize="1rem">
                          3045
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>

                  <VStack mt="1.15rem">
                    <HStack
                      justifyContent="space-between"
                      w="29rem"
                      alignItems="flex-start"
                    >
                      <HStack alignItems="flex-start">
                        <Image
                          src={avatar}
                          alt="avatar"
                          width="2.5rem"
                          height="2.5rem"
                        />
                        <VStack alignItems="flex-start" gap="0">
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            color="#000"
                          >
                            Alberto
                          </Text>
                          <Text fontSize="1rem">Bro, the second one </Text>
                          <HStack gap="2.25rem">
                            <Text fontSize="0.875rem">1w ago</Text>
                            <Text fontSize="0.875rem">Reply</Text>
                          </HStack>

                          <Button
                            variant="ghost"
                            padding="0"
                            transition="0"
                            fontSize="0.875rem"
                            _hover={{ bg: "none" }}
                          >
                            <HStack>
                              <Text>View 11 replies</Text>
                              <Image src={downArrow} alt="arrow" />
                            </HStack>
                          </Button>
                        </VStack>
                      </HStack>

                      <VStack justifyContent="center">
                        <Image
                          src={unLikeHeart}
                          alt="alt"
                          w="1.02106rem"
                          h="0.875rem"
                        />
                        <Text color="#616161" fontSize="1rem">
                          3045
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>

                  <VStack mt="1.15rem">
                    <HStack
                      justifyContent="space-between"
                      w="29rem"
                      alignItems="flex-start"
                    >
                      <HStack alignItems="flex-start">
                        <Image
                          src={avatar}
                          alt="avatar"
                          width="2.5rem"
                          height="2.5rem"
                        />
                        <VStack alignItems="flex-start" gap="0">
                          <Text
                            fontSize="0.875rem"
                            fontWeight="600"
                            color="#000"
                          >
                            Alberto
                          </Text>
                          <Text fontSize="1rem">Bro, the second one </Text>
                          <HStack gap="2.25rem">
                            <Text fontSize="0.875rem">1w ago</Text>
                            <Text fontSize="0.875rem">Reply</Text>
                          </HStack>

                          <Button
                            variant="ghost"
                            padding="0"
                            transition="0"
                            fontSize="0.875rem"
                            _hover={{ bg: "none" }}
                          >
                            <HStack>
                              <Text>View 11 replies</Text>
                              <Image src={downArrow} alt="arrow" />
                            </HStack>
                          </Button>
                        </VStack>
                      </HStack>

                      <VStack justifyContent="center">
                        <Image
                          src={unLikeHeart}
                          alt="alt"
                          w="1.02106rem"
                          h="0.875rem"
                        />
                        <Text color="#616161" fontSize="1rem">
                          3045
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>

                  <HStack padding="1rem" borderTop="2px solid #E0E0E0" w="100%">
                    <Input
                      placeholder="Add a comment..."
                      background="#F1F1F2"
                    />
                    <Button>Post</Button>
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          )}
        </>
      ) : (
        <Center height="calc(100vh - 300px)">
          <VStack spacing={4}>
            <TiktokLogo width="28" height="28" />
            <Text fontSize="xl" textAlign="center">
              It looks like you need to authenticate your TikTok account first.
              Please reach out to our support team for assistance.
            </Text>
          </VStack>
        </Center>
      )}
      {loading && <Loader />}
    </>
  );
};

export default TikTok;
