import * as yup from "yup";

export type CreateCampaignForm = {
  id?: string;
  opt_in_keywords: string[];
  opt_in_message: string;
  opt_out_keywords: string[];
  opt_out_message: string;
  help_keywords: string[];
  help_message: string;
  description: string;
  message_flow: string;
  message_samples: { id: number; value: string }[];
  us_app_to_person_usecase: string;
  has_embedded_links: boolean;
  has_embedded_phone: boolean;
};

export const defaultValues = {
  opt_in_keywords: [],
  opt_in_message: "",
  opt_out_keywords: [],
  opt_out_message: "",
  help_keywords: [],
  help_message: "",
  description: "",
  message_flow: "",
  message_samples: [],
  us_app_to_person_usecase: "",
  has_embedded_links: false,
  has_embedded_phone: false,
};

export const createCampaignValidation = yup.object().shape({
  opt_in_keywords: yup
    .array()
    .of(
      yup
        .object()
        .shape({ value: yup.string() })
    ),
  opt_in_message: yup.string().nullable().max(320, 'Max 320 characters allowed.'),
  opt_out_keywords: yup
    .array()
    .of(
      yup
        .object()
        .shape({ value: yup.string() })
    ),
  opt_out_message: yup.string().nullable(),
  help_keywords: yup
    .array()
    .of(
      yup
        .object()
        .shape({ value: yup.string() })
    ),
  help_message: yup.string().nullable(),
  description: yup.string().nullable().required("Description is required.").min(40, 'Min 40 characters allowed.').max(4096, 'Max 4096 characters allowed.'),
  message_flow: yup.string().nullable().required("Message Flow is required.").min(40, 'Min 40 characters allowed.').max(2048, 'Max 2048 characters allowed.'),
  message_samples: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().required("Message Samples are required"),
      })
    )
    .min(2, "At least two samples are required."),
  us_app_to_person_usecase: yup
    .string()
    .nullable()
    .required("Us App to person usecase is required."),
  has_embedded_links: yup.boolean().required("Has Embedded Links is required"),
  has_embedded_phone: yup.boolean().required("Has Embedded Phone is required"),
});
